<template>
  <v-dialog
    v-if="versionCount > 0"
    v-model="showDialog"
    :max-width="800"
    data-testid="hypo-review-history-dialog"
  >
    <template #activator="{ on }">
      <v-btn
        fab
        x-small
        :color="getAuditDialogBtnColor(versionCount)"
        class="lighten-5 elevation-1"
        v-on="on"
        data-testid="btn-hypo-review-history-dialog"
      >
        <v-icon>mdi-history</v-icon>
      </v-btn>
    </template>

    <DialogCard @closeDialog="showDialog = false">
      <template #title> Audit trail for hypo review </template>

      <v-data-table
        :disable-sort="true"
        :fixed-header="true"
        :headers="getTableHeaders()"
        :items="tableData"
        item-key="rowVersionId"
        :loading="isLoading"
        dense
      >
        <template #no-data>
          <UnableToLoadData v-if="state === loadingState.LOAD_ERRORED" />
        </template>

        <template #loading>
          <v-skeleton-loader
            v-for="i in skeletonCount"
            :key="`skeleton_${i}`"
            height="38"
            type="table-row"
          />
        </template>

        <template #item="{ item }">
          <tr>
            <td data-testid="item-review-status-text">
              <v-icon
                v-if="item.reviewState === 'reviewed'"
                small
                color="#3F9C35"
                class="icon-reviewed"
                >mdi-check-circle</v-icon
              >
              <v-icon v-else small color="grey" class="icon-reviewed"
                >mdi-minus-circle</v-icon
              >
              {{ $t('review-status.' + item.reviewState) }}
            </td>
            <td>
              <small data-testid="item-modified-date-time">
                <DateFormat
                  :value="item.modifiedAt.time"
                  format="dd-MMM-yyyy"
                  data-testid="item-modified-date"
                  class="d-block"
                />
                <DateFormat
                  :value="item.modifiedAt.time"
                  format="HH:mm:ss"
                  data-testid="item-modified-time"
                />
              </small>
            </td>
            <td>
              <small>
                <span class="d-block">
                  <VersionBy :versionBy="item.versionBy"
                /></span>
                <span data-testid="item-version-reason">{{
                  item.versionReason
                }}</span>
              </small>
            </td>
          </tr>
        </template>
      </v-data-table>
    </DialogCard>
  </v-dialog>
</template>

<script>
import Vue from 'vue'

import DialogCard from '@/components/DialogCard.vue'
import DateFormat from '@/components/DateFormat.vue'
import VersionBy from '@/components/VersionBy.vue'

import auditDialogMixin from '@/components/mixins/auditDialogMixin'
import loadingState from '@/constants/loadingState'
import { selectedPatientMapGetters } from '@/store/modules/selectedPatientModule'
import hourFormatter from '@/utils/date/hourFormatter'
import service from '@/services/hypo-service'

export default Vue.extend({
  name: 'HypoReviewHistoryDialog',
  components: {
    DateFormat,
    DialogCard,
    VersionBy,
    UnableToLoadData: () => import('@/components/UnableToLoadData.vue'),
  },
  mixins: [auditDialogMixin],
  props: {
    itemId: { type: Number, required: true },
    versionCount: { type: Number, required: true },
  },
  data() {
    return {
      state: loadingState.INITIAL,
      loadingState,
      data: null,
      showDialog: false,
    }
  },
  watch: {
    showDialog(newShowDialog) {
      if (newShowDialog) {
        this.loadData()
      }
    },
  },
  computed: {
    ...selectedPatientMapGetters(),
    tableData() {
      return this.data?.items || []
    },
    clockNotation() {
      return this.selectedPatientClockNotation(this.patientNo)
    },
    hourFormatString() {
      return hourFormatter(this.clockNotation)
    },
    isLoading() {
      return this.state === loadingState.LOADING
    },
    skeletonCount() {
      return this.versionCount > 10 ? 10 : this.versionCount
    },
  },
  methods: {
    getTableHeaders() {
      return [
        { text: 'Reviewed', value: 'date', sortable: false },
        { text: 'Modified', value: 'versionAt' },
        { text: 'By / reason', value: 'byReason' },
      ]
    },
    loadData() {
      this.state = loadingState.LOADING
      service
        .getReviewHistory(this.itemId)
        .then(response => {
          this.state = loadingState.LOAD_SUCCEEDED
          this.data = response
        })
        .catch(error => {
          this.state = loadingState.LOAD_ERRORED
          this.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
    },
  },
})
</script>
