<template>
  <div
    data-testid="hypo-review"
    class="review-wrapper"
    :class="{ 'box-shadow': addBoxShadow }"
  >
    <v-row class="mx-3 pt-3 mb-1">
      <v-col class="spacing-2 pb-0 d-flex align-center">
        <div v-if="item.isReviewPending" class="ml-auto d-flex align-center">
          <v-icon small color="grey" class="icon-reviewed"
            >mdi-minus-circle</v-icon
          >
          <span class="review-state-text">{{
            $t('review-status.' + item.reviewState)
          }}</span>
        </div>

        <div v-else class="ml-auto d-flex align-center">
          <v-icon small color="#3F9C35" class="icon-reviewed"
            >mdi-check-circle</v-icon
          >
          <span
            class="review-state-text"
            data-testid="hypo-review-state-text"
            >{{ $t('review-status.' + item.reviewState) }}</span
          >
          <DateFormat
            :value="item.reviewInfo.reviewedAt.time"
            format="dd MMM yyyy, HH:mm"
            class="ml-2"
            data-testid="hypo-review-date-time"
          />
        </div>

        <div v-if="item.versionCount" class="ml-3">
          <HypoReviewHistoryDialog
            :versionCount="item.versionCount"
            :itemId="hypoForm.id"
          />
        </div>
      </v-col>
    </v-row>

    <v-alert
      v-if="isLoadErrored"
      type="error"
      dense
      text
      class="mb-0"
      data-testid="hypo-review-error"
      >Unable to update hypo form review, please try again later</v-alert
    >
    <v-row class="mx-3 py-3">
      <v-col class="text-right">
        <v-btn
          rounded
          depressed
          class="elevation-1"
          color="default"
          @click="$emit('closeDialog', true)"
          data-testid="btn-close-hypo-form"
          >Close</v-btn
        >

        <template v-if="isReviewAllowed">
          <v-btn
            v-if="item.isReviewPending"
            color="secondary"
            rounded
            @click="setReviewed"
            class="ml-4 elevation-1"
            :disabled="!enabled"
            :loading="isLoading"
            data-testid="btn-mark-hypo-reviewed"
            >Mark as reviewed</v-btn
          >
          <v-btn
            v-else
            rounded
            @click="undoReview"
            class="ml-4 button-undo-review elevation-1"
            :loading="isLoading"
            data-testid="btn-undo-hypo-review"
            >Undo review</v-btn
          >
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue'
import service from '@/services/hypo-service'
import dateTimeWithTimeZone from '@/utils/date/dateTimeWithTimeZone'
import DateFormat from '@/components/DateFormat.vue'
import loadingState from '@/constants/loadingState'
import { hypoListMapActions } from '@/store/modules/hypoListModule'
import HypoReviewHistoryDialog from './_HypoReviewHistoryDialog.vue'
import permissionsGettersMixin from '@/components/mixins/store/permissionsGettersMixin'

export default Vue.extend({
  name: 'HypoReview',
  mixins: [permissionsGettersMixin],
  components: { HypoReviewHistoryDialog, DateFormat },
  props: {
    hypoForm: { type: Object, required: true },
    enabled: { type: Boolean, required: true },
    patientNo: { type: String, required: true },
    addBoxShadow: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      loadingState: loadingState.INITIAL,
      item: this.hypoForm.review,
    }
  },
  computed: {
    isLoading() {
      return this.loadingState === loadingState.LOADING
    },
    isLoadSucceeded() {
      return this.loadingState === loadingState.LOAD_SUCCEEDED
    },
    isLoadErrored() {
      return this.loadingState === loadingState.LOAD_ERRORED
    },
    isReviewAllowed() {
      return this.isUserAllowedToManagePatient && !this.hypoForm.isDeleted
    },
  },
  methods: {
    ...hypoListMapActions(),
    setReviewed() {
      this.loadingState = loadingState.LOADING
      service
        .setReviewed(this.hypoForm.id, this.patientNo, dateTimeWithTimeZone())
        .then(response => {
          if (response.isOk) {
            this.loadingState = loadingState.LOAD_SUCCEEDED
            this.item = { ...response.result }
            this.hypoListUpdateReviewStatus(response)
          } else {
            this.loadingState = loadingState.LOAD_ERRORED
          }
        })
        .catch(error => {
          this.loadingState = loadingState.LOAD_ERRORED
          this.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
    },
    undoReview() {
      this.loadingState = loadingState.LOADING
      service
        .undoReview(this.hypoForm.id, this.patientNo, dateTimeWithTimeZone())
        .then(response => {
          if (response.isOk) {
            this.loadingState = loadingState.LOAD_SUCCEEDED
            this.item = { ...response.result }
            this.hypoListUpdateReviewStatus(response)
          } else {
            this.loadingState = loadingState.LOAD_ERRORED
          }
        })
        .catch(error => {
          this.loadingState = loadingState.LOAD_ERRORED
          this.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
    },
  },
})
</script>

<style lang="scss" scoped>
.review-wrapper {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  transform: translateZ(0);
  background-color: $nn-TB_T95;
}
.icon-reviewed {
  margin-left: auto;
  margin-right: 4px;
}
.button-undo-review {
  color: white;
  background: $nn-granite-gray !important;
}
.box-shadow {
  box-shadow: 0 0 10px $nn-D_T80;
}
.review-state-text {
  line-height: 1;
}
</style>
