<template>
  <v-dialog
    v-if="versionCount > 0"
    v-model="showDialog"
    :max-width="800"
    :fullscreen="true"
    data-testid="hypo-history-dialog"
  >
    <template #activator="{ on }">
      <v-btn
        fab
        x-small
        :color="getAuditDialogBtnColor(versionCount)"
        class="lighten-5 elevation-1"
        v-on="on"
      >
        <v-icon>mdi-history</v-icon>
      </v-btn>
    </template>

    <DialogCard @closeDialog="showDialog = false">
      <template #title> Audit trail for hypo </template>

      <div class="sticky-table-wrapper">
        <table v-if="data" :style="`width: ${tableWidth};`">
          <tbody>
            <tr>
              <th :style="`width: ${questionCol}px;`">Modified</th>
              <td
                v-for="(hypoForm, index) in data.items"
                :key="`episode_start_${index}`"
                :style="`width: ${answerCol}px;`"
              >
                <DateFormat :value="hypoForm.versionAt.time" class="mr-5" />
                <DateFormat
                  :format="timeFormatString"
                  :value="hypoForm.versionAt.time"
                />
              </td>
            </tr>

            <tr>
              <th>By</th>
              <td
                v-for="(hypoForm, index) in data.items"
                :key="`episode_start_${index}`"
              >
                <VersionBy :versionBy="hypoForm.versionBy" />
              </td>
            </tr>

            <tr>
              <th>Reason</th>
              <td
                v-for="(hypoForm, index) in data.items"
                :key="`episode_start_${index}`"
              >
                {{ hypoForm.versionReason }}
              </td>
            </tr>

            <tr>
              <th>Episode start time</th>

              <td
                v-for="(hypoForm, index) in data.items"
                :key="`episode_start_${index}`"
              >
                <DateFormat :value="hypoForm.startedAt" class="mr-5" />
                <DateFormat
                  :format="timeFormatString"
                  :value="hypoForm.startedAt"
                />
              </td>
            </tr>
            <tr>
              <th>Episode end time</th>
              <td
                v-for="(hypoForm, index) in data.items"
                :key="`episode_start_${index}`"
              >
                <DateFormat :value="hypoForm.stoppedAt" class="mr-5" />
                <DateFormat
                  :format="timeFormatString"
                  :value="hypoForm.stoppedAt"
                />
              </td>
            </tr>
            <tr>
              <th>Is severe</th>
              <td
                v-for="(hypoForm, index) in data.items"
                :key="`episode_severe_${index}`"
              >
                <SevereChip
                  v-if="hypoForm.isSevere"
                  :showAsDeleted="hypoForm.isDeleted"
                  no-margin-left
                />
              </td>
            </tr>

            <tr v-if="isAnyDeleted">
              <th>Is deleted by patient</th>
              <td
                v-for="(hypoForm, index) in data.items"
                :key="`episode_severe_${index}`"
              >
                {{ hypoForm.isDeleted ? 'Yes' : '' }}
              </td>
            </tr>
            <tr>
              <th>BGM value</th>
              <td
                v-for="(hypoForm, index) in data.items"
                :key="`bgm_values_${index}`"
              >
                <HypoHistoryBgm
                  :hypo-form="hypoForm"
                  :time-format-string="timeFormatString"
                />
              </td>
            </tr>

            <tr v-for="(item, q) in data.questionIds" :key="`question_${q}`">
              <th>{{ item.title }}</th>
              <td
                v-for="(answer, index) in data.items"
                :key="`answer_${index}_${q}`"
              >
                <HypoFormAnswer
                  :question="getAnswer(item.id, answer)"
                  :date-format-string="dateFormatString"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </DialogCard>
  </v-dialog>
</template>

<script>
import Vue from 'vue'

import VersionBy from '@/components/VersionBy.vue'
import DialogCard from '@/components/DialogCard.vue'
import DateFormat from '@/components/DateFormat.vue'
import HypoFormAnswer from './_HypoFormAnswer.vue'
import HypoHistoryBgm from './_HypoHistoryBgm.vue'

import auditDialogMixin from '@/components/mixins/auditDialogMixin'
import loadingState from '@/constants/loadingState'
import { selectedPatientMapGetters } from '@/store/modules/selectedPatientModule'
import hourFormatter from '@/utils/date/hourFormatter'
import service from '@/services/hypo-service'
import SevereChip from '../SevereChip'

export default Vue.extend({
  name: 'HypoHistoryDialog',
  components: {
    SevereChip,
    HypoHistoryBgm,
    DateFormat,
    HypoFormAnswer,
    DialogCard,
    VersionBy,
  },
  mixins: [auditDialogMixin],
  props: {
    itemId: { type: Number, required: true },
    versionCount: { type: Number, required: true },
  },
  data() {
    return {
      state: loadingState.INITIAL,
      loadingState,
      data: null,
      showDialog: false,
      questionCol: 225,
      answerCol: 255,
    }
  },
  watch: {
    showDialog(newShowDialog) {
      if (newShowDialog) {
        this.loadData()
      }
    },
  },
  computed: {
    ...selectedPatientMapGetters(),
    clockNotation() {
      return this.selectedPatientClockNotation(this.patientNo)
    },
    dateFormatString() {
      return `d MMM yyyy, ${hourFormatter(
        this.selectedPatientClockNotation(this.patientNo)
      )}`
    },
    timeFormatString() {
      return hourFormatter(this.selectedPatientClockNotation(this.patientNo))
    },
    isLoading() {
      return this.state === loadingState.LOADING
    },
    isAnyDeleted() {
      return this.data.items.some(x => x.isDeleted)
    },
    tableWidth() {
      if (this.data.items.length < 3) return '100%'

      return this.questionCol + this.data.items.length * this.answerCol + 'px'
    },
  },
  methods: {
    getAnswer(questionId, form) {
      const result = form.questions?.find(x => x.id === questionId)
      if (!result) return {}

      return result
    },
    loadData() {
      this.state = loadingState.LOADING
      service
        .getHypoHistory(this.itemId)
        .then(response => {
          this.state = loadingState.LOAD_SUCCEEDED
          this.data = response
        })
        .catch(error => {
          this.state = loadingState.LOAD_ERRORED
          this.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
    },
  },
})
</script>

<style lang="scss" scoped>
.sticky-table-wrapper {
  width: 100%;
  overflow-x: scroll;
  position: relative;
  border-left: 1px solid $nn-D_T80;

  table {
    border-collapse: collapse;
    table-layout: fixed;
    border-top: 1px solid $nn-D_T80;

    td,
    th {
      box-shadow: inset -1px -1px 0 0 $nn-D_T80;
      text-align: left;
      padding: 4px;
      font-size: 80%;
      vertical-align: text-top;
    }

    th:first-child {
      position: sticky;
      left: 0;
      z-index: 2;
      box-shadow: inset -2px -1px 0 0 $nn-D_T80;
    }

    tr:nth-child(even),
    tr:nth-child(even) th:first-child {
      background-color: #f2f2f2;
    }

    tr:nth-child(odd) th:first-child {
      background-color: white;
    }
  }
}
</style>
