<template>
  <v-chip
    :color="chipColor"
    :text-color="textColor"
    label
    small
    :class="{ 'ml-3': !noMarginLeft }"
    >Severe</v-chip
  >
</template>

<script>
export default {
  name: 'SevereChip',
  props: {
    noMarginLeft: { type: Boolean, required: false, default: false },
    showAsDeleted: { type: Boolean, required: false, default: false },
  },
  computed: {
    chipColor() {
      return this.showAsDeleted ? '#E6E6E6' : '#EB7765'
    },
    textColor() {
      return this.showAsDeleted ? '' : 'white'
    },
  },
}
</script>
