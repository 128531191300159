<template>
  <v-row
    :class="[
      { 'row-highlighted': highlight },
      { 'no-border-bottom': noBorderBottom },
      'no-gutters',
    ]"
    data-testid="hypo-question-row"
  >
    <v-col
      class="ml-6 pr-3 py-4 question question-title"
      data-testid="hypo-question-title"
    >
      {{ title }}
    </v-col>
    <v-col
      class="mr-6 mt-auto py-4 question question-answer spacing-2"
      data-testid="hypo-question-answer"
    >
      <slot></slot>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'HypoQuestionRow',
  props: {
    title: { type: String, required: true },
    highlight: { type: Boolean, required: false, default: false },
    noBorderBottom: { type: Boolean, required: false, default: false },
  },
}
</script>

<style lang="scss" scoped>
.question {
  border-bottom: 0.5px solid $nn-D_T80;
}
.row-highlighted {
  background-color: rgba(204, 197, 176, 0.15);
}

.no-border-bottom {
  .question {
    border-bottom: none;
  }
}
.question-title {
  font-size: 14px;
  color: $nn-D_T40;
  font-weight: bold;
  letter-spacing: -0.02em;
}

.question-answer {
  color: $nn-D_T20;
  font-weight: 500;
}
</style>
