<template>
  <v-card class="main-card" data-testid="hypo-form">
    <div class="hypo-form-header" :class="{ 'elevation-2': addBoxShadow }">
      <v-row class="hypo-form-row">
        <v-col>
          <v-btn
            v-if="isDialog"
            fab
            icon
            text
            @click="emitClose"
            class="float-right mt-1 main-card__header__close-wrapper"
            data-testid="btn-x-close-hypo-form"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <div class="mt-4 d-inline-flex align-center">
            <h4 class="d-inline-flex align-center mr-3">
              <span class="main-card__header__icon-wrapper">
                <v-icon small class="main-card__header__icon">mdi-water</v-icon>
              </span>
              <span data-testid="hypo-form-title">
                Hypo form {{ hypoForm.hypoNo }}
              </span>
            </h4>
            <HypoHistoryDialog
              :item-id="hypoForm.id"
              :version-count="hypoForm.versionCount"
            />
            <SevereChip
              v-if="hypoForm.isSevere"
              :showAsDeleted="hypoForm.isDeleted"
              class="ml-2"
              no-margin-left
            />
            <DeletedByPatientChip v-if="hypoForm.isDeleted" class="ml-2" />
          </div>

          <div class="spacing-2 mt-3 main-card__date-row">
            Patient submitted on
            <DateFormat
              :format="dateFormatString"
              :value="hypoForm.reported.time"
              data-testid="hypo-reported-on"
            />

            <span v-if="hypoForm.lastUpdated">
              - Last updated on
              <DateFormat
                :format="dateFormatString"
                :value="hypoForm.lastUpdated.time"
                data-testid="hypo-updated-on"
              />
            </span>
          </div>
        </v-col>
      </v-row>
    </div>
    <div
      class="on-scroll-container"
      :class="{ 'add-scroll-padding': addScrolledPadding }"
      ref="scrollContainer"
      v-scroll.self="onScroll"
    >
      <HypoQuestionRow title="Episode start time" :highlight="true">
        <DateFormat :value="hypoForm.startedAt" class="mr-5" />
        <DateFormat :format="timeFormatString" :value="hypoForm.startedAt" />
      </HypoQuestionRow>

      <HypoQuestionRow
        title="Episode end time"
        :highlight="true"
        :no-border-bottom="true"
      >
        <DateFormat :value="hypoForm.stoppedAt" class="mr-5" />
        <DateFormat :format="timeFormatString" :value="hypoForm.stoppedAt" />
      </HypoQuestionRow>

      <HypoBgmRow :form="hypoForm" :time-format-string="timeFormatString" />

      <HypoQuestion
        v-for="(item, index) in this.hypoForm.questions"
        :key="index"
        :question="item"
        :date-format-string="dateFormatString"
      />
    </div>
    <HypoReview
      :patientNo="patientNo"
      :hypoForm="hypoForm"
      @closeDialog="emitClose"
      :enabled="scrolledToBottom"
      :addBoxShadow="addBoxShadow"
    />
  </v-card>
</template>

<script>
import Vue from 'vue'
import HypoQuestion from './_HypoQuestion.vue'
import hourFormatter from '@/utils/date/hourFormatter'
import { selectedPatientMapGetters } from '@/store/modules/selectedPatientModule'
import HypoQuestionRow from './_HypoQuestionRow.vue'
import DateFormat from '../DateFormat.vue'
import HypoBgmRow from './_HypoBGMRow.vue'
import HypoReview from './review/HypoReview.vue'
import HypoHistoryDialog from './history/HypoHistoryDialog.vue'
import SevereChip from './SevereChip'
import DeletedByPatientChip from './DeletedByPatientChip'

export default Vue.extend({
  name: 'HypoForm',
  components: {
    DeletedByPatientChip,
    SevereChip,
    HypoHistoryDialog,
    HypoReview,
    HypoBgmRow,
    DateFormat,
    HypoQuestion,
    HypoQuestionRow,
  },

  props: {
    patientNo: { type: String, required: true },
    hypoForm: { type: Object, required: true },
    isDialog: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      scrolledToBottom: false,
      addScrolledPadding: false,
      addBoxShadow: false,
    }
  },
  computed: {
    ...selectedPatientMapGetters(),
    dateFormatString() {
      return `d MMM yyyy, ${hourFormatter(
        this.selectedPatientClockNotation(this.patientNo)
      )}`
    },
    timeFormatString() {
      return hourFormatter(this.selectedPatientClockNotation(this.patientNo))
    },
  },
  methods: {
    emitClose() {
      this.$emit('closeDialog', true)
    },
    onScroll(e) {
      if (
        e.target.scrollTop + e.target.offsetHeight >
        e.target.scrollHeight - 100
      ) {
        this.scrolledToBottom = true
      }
    },
  },
  mounted() {
    const target = this.$refs.scrollContainer
    this.scrolledToBottom = target.scrollHeight < 550
    this.addScrolledPadding = target.scrollHeight > 550
    this.addBoxShadow = target.scrollHeight > 550
  },
})
</script>

<style lang="scss" scoped>
.hypo-form-header {
  top: 0;
  position: sticky;
  background: white;
  z-index: 1;
  padding-left: 24px;
  padding-right: 24px;
}

.main-card {
  position: relative;
  overflow-x: hidden;
  transform: translateZ(0);

  &__header {
    &__icon-wrapper {
      background-color: $nn-LR_T90;
      border-radius: 4px;
      width: 22px;
      height: 22px;
      display: inline-grid;
      place-items: center;
      margin-right: 4px;
    }
    &__icon {
      color: #e6553f;
    }
    &__close-wrapper {
      margin-top: -20px;
      margin-right: -14px;
    }
  }

  &__date-row {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: $nn-D_T20;
  }
}

.on-scroll-container {
  max-height: 550px;
  display: block;
  overflow-y: scroll;
  padding-bottom: 140px;
}
.add-scroll-padding {
  padding-bottom: 170px;
}
</style>
