<template>
  <HypoQuestionRow :title="question.title">
    <div v-if="question.type === 'MultiChoicePicker'">
      <ul>
        <li v-for="(answer, index) in question.answer" :key="index">
          {{ answer }}
        </li>
      </ul>
    </div>
    <div v-else v-for="(answer, index) in question.answer" :key="index">
      <span v-if="question.type === 'DatePicker'">
        <DateFormat :value="answer" :format="dateFormatString" />
      </span>

      <span v-else>
        {{ answer }}
      </span>
    </div>
  </HypoQuestionRow>
</template>

<script>
import DateFormat from '@/components/DateFormat'
import { format24h } from '@/utils/date/hourFormatter'
import HypoQuestionRow from './_HypoQuestionRow'

export default {
  name: 'HypoQuestion',
  components: {
    DateFormat,
    HypoQuestionRow,
  },
  props: {
    question: { type: Object, required: true },
    dateFormatString: {
      type: String,
      required: true,
      default: `d MMM yyyy, ${format24h}`,
    },
  },
}
</script>

<style lang="scss" scoped>
li {
  margin-bottom: 8px;
}
</style>
