<template>
  <div data-testid="hypo-form-answer">
    <div v-if="!question || !question.type">-</div>
    <div v-else-if="question.type === 'MultiChoicePicker'">
      <ul>
        <li v-for="(answer, index) in question.answer" :key="index">
          {{ answer }}
        </li>
      </ul>
    </div>
    <div v-else v-for="(answer, index) in question.answer" :key="index">
      <span v-if="question.type === 'DatePicker'">
        <DateFormat :value="answer" :format="dateFormatString" />
      </span>

      <span v-else>
        {{ answer }}
      </span>
    </div>
  </div>
</template>

<script>
import DateFormat from '@/components/DateFormat'
import { format24h } from '@/utils/date/hourFormatter'

export default {
  name: 'HypoFormAnswer',
  components: { DateFormat },
  props: {
    question: { type: Object, required: true },
    dateFormatString: {
      type: String,
      required: true,
      default: `d MMM yyyy, ${format24h}`,
    },
  },
}
</script>
