<template>
  <div data-testid="hypo-history-bgm">
    <template v-if="hypoForm.lowestValue">
      <SmbgValue :measure="hypoForm.lowestValue" :noLeftPadding="true" />

      <div v-if="hasMultipleSmgbs">
        <v-divider class="mt-1 mb-2" />

        <table>
          <tr v-for="(item, index) in hypoForm.smbgList" :key="index">
            <td>
              <DateFormat :value="item.doneAt.time" class="mr-5" />
              <DateFormat
                :value="item.doneAt.time"
                :format="timeFormatString"
              />
            </td>
            <td class="pl-5">
              <SmbgValue :measure="item" :noLeftPadding="true" />
            </td>
          </tr>
        </table>
      </div>
    </template>
    <template v-else>No BG value </template>
  </div>
</template>

<script>
import DateFormat from '@/components/DateFormat'
import SmbgValue from '@/components/SmbgValue'
import { format24h } from '@/utils/date/hourFormatter'

export default {
  name: 'HypoHistoryBgm',
  components: { DateFormat, SmbgValue },
  props: {
    hypoForm: { type: Object, required: true },
    timeFormatString: {
      type: String,
      required: true,
      default: `d MMM yyyy, ${format24h}`,
    },
  },
  computed: {
    hasMultipleSmgbs() {
      return this.hypoForm.smbgList?.length > 1
    },
  },
}
</script>
