<template>
  <div data-testid="hypo-form-dialog">
    <v-dialog v-model="showDialog" :scrollable="true" :max-width="800">
      <template #activator="{ on }">
        <div v-on="on">
          <slot />
        </div>
      </template>

      <HypoForm
        v-if="hypoForm"
        :patientNo="patientNo"
        :hypoForm="hypoForm"
        :isDialog="true"
        @closeDialog="showDialog = false"
        :key="`hypo-form-dialog-${dialogRevision}`"
      />

      <v-card
        v-if="state === loadingState.LOAD_ERRORED"
        class="hypo-load-data-error"
      >
        <v-alert type="error" dense text> Error: Could not load data. </v-alert>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import loadingState from '@/constants/loadingState'
import service from '@/services/hypo-service'
import HypoForm from '@/components/hypo-form/_HypoForm'

export default Vue.extend({
  name: 'HypoFormDialog',
  components: {
    HypoForm,
  },
  props: {
    patientNo: { type: String, required: true },
    hypoId: { type: [String, Number], required: true },
  },
  data() {
    return {
      state: loadingState.INITIAL,
      loadingState,
      hypoForm: null,
      showDialog: false,
      dialogRevision: 0,
    }
  },
  watch: {
    showDialog: function (newShowDialog) {
      if (newShowDialog) {
        this.dialogRevision++
        this.loadDataForHypo()
      }
    },
  },
  methods: {
    loadDataForHypo() {
      this.hypoForm = null
      this.state = loadingState.LOADING
      service
        .getHypo(this.hypoId)
        .then(response => {
          this.state = loadingState.LOAD_SUCCEEDED
          this.hypoForm = response
        })
        .catch(error => {
          this.state = loadingState.LOAD_ERRORED
          this.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
    },
  },
})
</script>

<style lang="scss" scoped>
.hypo-load-data-error {
  color: $nn-lava-red;
}

.hypo-form-cta {
  color: inherit;
  display: flex;
  align-items: center;
  &:hover {
    text-decoration: underline;
  }
}
</style>
