<template>
  <v-row class="no-gutters" data-testid="hypo-bgm-row">
    <v-col class="mx-6 question">
      <v-row class="no-gutters">
        <v-col class="py-4 question-title"> Lowest BG value </v-col>
        <v-col class="mt-auto py-4 question-answer spacing-2">
          <v-btn
            v-if="hasMultipleSmbgs"
            x-small
            depressed
            fab
            text
            class="float-right ml-4 button-expand-smbgs"
            @click="showMoreEpisodes = !showMoreEpisodes"
            :color="showMoreEpisodes ? 'secondary' : 'black'"
            :outlined="!showMoreEpisodes"
            data-testid="btn-lowest-bgm-toggle-list"
            ><v-icon>{{
              showMoreEpisodes ? 'mdi-chevron-up' : 'mdi-chevron-down'
            }}</v-icon></v-btn
          >
          <SmbgValue
            v-if="form.lowestValue"
            :measure="form.lowestValue"
            :noLeftPadding="true"
            data-testid="hypo-lowest-bgm"
          ></SmbgValue>
          <div v-else class="pl-2" data-testid="hypo-lowest-bgm">
            No BG value
          </div>
        </v-col>
      </v-row>

      <v-scroll-y-transition :hide-on-leave="true">
        <v-row
          v-if="showMoreEpisodes"
          class="ml-3 mr-12 border-top-row no-gutters py-2"
        >
          <v-col sm="2" class="pt-2 question-title">All values</v-col>
          <v-col class="pt-2">
            <v-row
              v-for="(item, index) in form.smbgList"
              :key="index"
              class="no-gutters mb-2"
            >
              <v-col sm="5" class="text-right pr-4">
                <DateFormat
                  :value="item.doneAt.time"
                  class="mr-5"
                  data-testid="bgm-done-at-date"
                />
                <DateFormat
                  :value="item.doneAt.time"
                  :format="timeFormatString"
                  data-testid="bgm-done-at-time"
                />
              </v-col>
              <v-col class="pl-2">
                <SmbgValue :measure="item" :noLeftPadding="true" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-scroll-y-transition>
    </v-col>
  </v-row>
</template>

<script>
import SmbgValue from '@/components/SmbgValue'
import DateFormat from '../DateFormat'
import { format24h } from '@/utils/date/hourFormatter'

export default {
  name: 'HypoBgmRow',
  components: { DateFormat, SmbgValue },
  props: {
    form: { type: Object, required: true },
    timeFormatString: {
      type: String,
      required: true,
      default: `d MMM yyyy, ${format24h}`,
    },
  },
  data() {
    return {
      showMoreEpisodes: false,
    }
  },
  computed: {
    hasMultipleSmbgs() {
      return this.form.smbgList?.length > 1
    },
  },
}
</script>

<style lang="scss" scoped>
.question {
  border-bottom: 0.5px solid $nn-D_T80;
}
.border-top-row {
  border-top: 0.5px solid $nn-D_T80;
}
.row-highlighted {
  background-color: rgba(204, 197, 176, 0.15);

  .question {
    border-bottom: none;
  }
}

.button-expand-smbgs {
  margin-top: -7px;
}

.question-title {
  font-size: 14px;
  color: $nn-D_T40;
  font-weight: bold;
  letter-spacing: -0.02em;
}

.question-answer {
  color: $nn-D_T20;
  font-weight: 500;
}
</style>
