<template>
  <v-chip label color="#E6E6E6" data-testid="hypo-removed-label" small>
    Deleted by patient
  </v-chip>
</template>

<script>
export default {
  name: 'DeletedByPatientChip',
}
</script>

<style scoped></style>
